
/* hmtl, body */
html {
  font-size: 13px;
  font-weight: 400;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F7FA !important;
  color: #203040 !important;
}


/* main layout*/
.app {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.app.blurred {
  filter: blur(3px);
}

.app-sidebar {
  flex: 0 0 264px;  
  overflow: auto;
  display: flex;
}

.app-content {
  padding: 2rem 4rem;
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
}

.row {
  margin-left: 0;
  margin-right: 0;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 0;
  padding-right: 0;
}

/* Paragraphs */
h1 {
  font-size: 2.3rem;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
h3 {
  font-size: 1.3rem;
  font-weight: 500;
}
h4 {
  font-size: 1.1rem;
  font-weight: 500;
}
h5 {
  font-weight: 500;
}
strong, .text-bold {
  font-weight: 500;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
}

.small-box {
  transform: scale(0.9);
}

.small {
  font-size: 90% !important;
  color: rgba(32, 48, 64, 0.75);
}
.big {
  font-size: 115%;
}


/* text-decoration */
.highlight {
  background-color: rgba(0, 122, 242, 0.08);
  border-radius: 6px;
  margin: 0.5rem;
  padding: 0.5rem;
  font-weight: 500;
  text-align: center;
}
.text-red {
  color: #FF0000;
}
.bg-red {
  background-color: #FF0000;
  color: #FFFFFF;
}
.text-green {
  color: #5AB369;
}
.bg-green {
  background-color: #5AB369;
  color: #FFFFFF;
}
.text-orange {
  color: #FF8000;
}
.bg-orange {
  background-color: #FF8000;
  color: #FFFFFF;
}



/* link */
a, a:hover, a:link, a:visited, a:active, a:focus {
  text-decoration: none;
  color: inherit;
}


/* Logo */

.logo {
  flex: 0 0;
  margin-bottom: 0.25rem;  
}
.logo a {
  display: block;  
  height: 90px;
  margin: 1rem;
  background-image: url('./images/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;  
}

.logo a h1 {
  font-size: 1.85rem;
  font-weight: 500;
  vertical-align: middle;
  margin: 0;
}

.vendor {
  text-align: center;
  padding: 0.6rem 0;
  height: 48px;
  background-color: #F5F7FA;
}
.vendor img {
  max-height: 100%;
  max-width: 60%;
  margin-right: 10px;
  vertical-align: baseline;
}

/* Sidebar*/

.sidebar {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;  
  margin-right: 8px;
}
.sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;  
}

.sidebar .menu-main {
  flex: 1 1;
  margin-bottom: 0.25rem;  
}

.sidebar .menu-actions {
  flex: 0 0;  
}

.sidebar .menu-main ul li {
  font-weight: 500;
  text-transform: uppercase;
  border-left: 2px solid #FFF;
  border-right: 2px solid #FFF;
}
.sidebar .menu-main ul li.active {
  background-color: #F5F7FA;
  color: #007AF2;
  border-left: 2px solid #007AF2;
  border-right: 2px solid #007AF2;
}
.sidebar .menu-main ul li:hover{
  background-color: #F5F7FA;
  color: #007AF2;
  border-left: 2px solid #F5F7FA;
  border-right: 2px solid #F5F7FA;
}
.sidebar .menu-main ul li.active:hover{
  background-color: #F5F7FA;
  color: #007AF2;
  border-left: 2px solid #007AF2;
  border-right: 2px solid #007AF2;
}

.sidebar .menu-main ul li a {
  display: block;
  padding: 1rem 0 1rem 5.5rem;
  background-repeat: no-repeat;
  background-position: 23px 50%;
  white-space: nowrap;
}

.sidebar .menu-main ul li a.overview {
  background-image: url('./images/overview-a.svg');
}
.sidebar .menu-main ul li a.overview {
  background-image: url('./images/overview.svg');
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.overview, .sidebar ul li:hover a.overview {
  background-image: url('./images/overview-a.svg');  
}

.sidebar .menu-main ul li a.accounts {
  background-image: url('./images/accounts-a.svg');
}
.sidebar .menu-main ul li a.accounts {
  background-image: url('./images/accounts.svg');
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.accounts, .sidebar ul li:hover a.accounts {
  background-image: url('./images/accounts-a.svg');  
}

.sidebar .menu-main ul li a.managers {
  background-image: url('./images/managers-a.svg');
}
.sidebar .menu-main ul li a.managers {
  background-image: url('./images/managers.svg');
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.managers, .sidebar ul li:hover a.managers {
  background-image: url('./images/managers-a.svg');  
}

.sidebar .menu-main ul li a.resellers {
  background-image: url('./images/resellers-a.svg');
}
.sidebar .menu-main ul li a.resellers {
  background-image: url('./images/resellers.svg');
  background-position: 23px 50%;
}
.sidebar .menu-main ul li.active a.resellers, .sidebar ul li:hover a.resellers {
  background-image: url('./images/resellers-a.svg');  
}

.sidebar .menu-main ul li a.customers {
  background-image: url('./images/customers-a.svg');
}
.sidebar .menu-main ul li a.customers {
  background-image: url('./images/customers.svg');
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.customers, .sidebar ul li:hover a.customers {
  background-image: url('./images/customers-a.svg');  
}

.sidebar .menu-main ul li a.firmwares {
  background-image: url('./images/firmwares-a.svg');
}
.sidebar .menu-main ul li a.firmwares {
  background-image: url('./images/firmwares.svg');
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.firmwares, .sidebar ul li:hover a.firmwares {
  background-image: url('./images/firmwares-a.svg');  
}

.sidebar .menu-main ul li a.configs {
  background-image: url('./images/configs-a.svg');  
}
.sidebar .menu-main ul li a.configs {
  background-image: url('./images/configs.svg');  
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.configs, .sidebar ul li:hover a.configs {
  background-image: url('./images/configs-a.svg');    
}

.sidebar .menu-main ul li a.asics {
  background-image: url('./images/asics-a.svg');  
}
.sidebar .menu-main ul li a.asics {
  background-image: url('./images/asics.svg');  
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.asics, .sidebar ul li:hover a.asics {
  background-image: url('./images/asics-a.svg');    
}

.sidebar .menu-main ul li a.stats {
  background-image: url('./images/stats-a.svg');  
}
.sidebar .menu-main ul li a.stats {
  background-image: url('./images/stats.svg');  
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.stats, .sidebar ul li:hover a.stats {
  background-image: url('./images/stats-a.svg');    
}

.sidebar .menu-main ul li a.asic-types {
  background-image: url('./images/asic-types-a.svg');  
}
.sidebar .menu-main ul li a.asic-types {
  background-image: url('./images/asic-types.svg');  
  background-position: 24px 50%;
}
.sidebar .menu-main ul li.active a.asic-types, .sidebar ul li:hover a.asic-types {
  background-image: url('./images/asic-types-a.svg');    
}

.sidebar .menu-main ul li a.logs {
  background-image: url('./images/logs-a.svg');
}
.sidebar .menu-main ul li a.logs {
  background-image: url('./images/logs.svg');  
  background-position: 26px 50%;
}
.sidebar .menu-main ul li.active a.logs, .sidebar ul li:hover a.logs {
  background-image: url('./images/logs-a.svg');  
}



.sidebar .panel {
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 64, 128, 0.15);
  border-radius: 0;
  padding: 0;
}


.sidebar .menu-actions .actions {
  padding: 0.25rem 2rem 1.5rem 2rem;
}
.sidebar .menu-actions .actions li button{
  margin: 0.25rem 0;
  width: 100%;
}

.sidebar .status {  
  padding: 1.5rem 2rem 0.25rem 2rem;
}
.sidebar .status .status-content {
  border-radius: 0.5rem;
  background-color: #F5F7FA;
  padding: 0.75rem 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(32, 48, 64, 0.5);
}
.sidebar .status .status-content > div {
  display: flex;
  align-items: center;
}
.sidebar .status .status-green {
  text-transform: uppercase;
  color: #5AB369;
}
.sidebar .status .status-red {
  text-transform: uppercase;
  color: #FF0000;
}
.sidebar .status .led-green, .sidebar .status .led-red {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 0.5rem;
}
.sidebar .status .led-green {
  background-color: #5AB369;
}
.sidebar .status .led-red {
  background-color: #FF0000;
}

.sidebar .model {
  font-family: terminator_genisysregular;
  font-size: 1.5rem;
  border-bottom: 2px solid #F5F7FA;
  text-align: center;
  height: 1.2rem;
  margin: 0.75rem 2rem 0.5rem 2rem;
}
.sidebar .model span {
  background-color: #fff;
  padding: 0.5rem;
}

.divider {
  margin: 0.75rem 0;
  background-color: transparent;
  color: rgba(32, 48, 64, 0.6);
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;  
  text-transform: uppercase;
  width: 100%;
}

.divider span {
  padding: 0 0.5rem;
  background-color: #ffffff;  
}

.divider:after {
  content: " ";
  border-top: 1px solid rgba(32, 48, 64, 0.1);
  display: block;
  margin-top: -0.7rem;
  margin-bottom: 0.7rem;
}

.divider:hover {
  background-color: transparent !important; 
  color: rgba(32, 48, 64, 0.5) !important;
}


/* page Layout*/
.page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.page .panel {
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 64, 128, 0.15);
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem;
  margin: 0.75rem;
  flex: 1 1 auto;
  position: relative;  
}

.page .panel-title {
  margin: 0.75rem;
  position: relative;
}
.page-title {
  flex: 0 0;
}

.panel.panel-transparent {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.panel-title button.close {
  position: absolute;
  top: 1.15rem;
  right: 0.25rem;
}
.panel-title button.close:focus {
  box-shadow: none;
  outline: none;
}

.page .panel-textarea {
  padding: 1rem 0 1rem 1rem;
}

.page .panel-textarea textarea {
  border: none;
  background-color: #ffffff;
}

.page .panel-chart {
  overflow: hidden;
}


/* tables */
table {
  width: 100%;
}
table td, table th {
  padding: 0.75rem;
}
table th {
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
}
table th:first-of-type, table td:first-of-type {
  padding-left: 1.5rem;
}
table th:last-of-type, table td:last-of-type {
  padding-right: 1.5rem;
}
table td {
  vertical-align: top;
}
table thead tr th {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(0, 122, 242, 0.15);
}
table tbody tr {
  border-top: none;
}

table tbody tr:hover {
  background-color: rgba(0, 122, 242, 0.02);
}

table.bordered {
  border-collapse: separate;
  border-spacing: 0;
}
table.bordered tbody tr td {
  border-top: 1px solid rgba(0, 122, 242, 0.15);
}
table.bordered thead tr th {
  border: none;
}
table td.actions {
  text-align: right;
  width: 2rem;
}
table .actions .btn {
  padding: 0 1rem 0 2rem;
}

table tr.has-extra {
  cursor: pointer;  
}
table tr.extra {
  background-color: #F5F7FA;
}
table tr.extra:hover, table tr.extra:hover tr {
  background-color: #F5F7FA;
}
table tr.extra td {
  border-top: 1px solid rgba(0, 122, 242, 0) !important;
}

/* Component Overview Summary */
.component.overview-summary {
  padding-top: 0.25rem;
}
.component.overview-summary .panel {
  margin: 0.4rem;
  justify-content: center;
  align-items: center; 
  padding: 1.5rem; 
}
.component.overview-summary .panel h2, .component.overview-summary .panel p {
  text-align: center;
}
.component.overview-summary .panel p {
  color: rgba(32, 48, 64, 0.5);
}


/* Component Settings Chain*/
.selection {
  background: rgba(0, 123, 255, 0.01);
  border-radius: 0.1em;
  border: 2px solid rgba(0, 123, 255, 0.1);
}

.chain-row-1 {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(32, 48, 64, 0.1);
}
.chain-row-1 .col {
  margin-right: 1rem;  
}

.chain-row-2 {
  cursor: crosshair;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  
}

.chain-row-2 .col {
  padding: 0.4rem 2rem 0.4rem 0;
  flex: 0 0 155px;  
}

.chain-row-2 .col .chip {
  display: flex;
  background-color: rgba(0, 122, 242, 0.08);
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 122, 242, 0);  
}
.chain-row-2 .col .chip.selected {
  border: 1px solid rgba(0,123,255, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.2);
}
.chain-row-2 .col span {
  display: block;
  width: 20px;
  line-height: 1.9;
  
}
.chain-row-2 .col .freq {
  padding: 0.15rem;
  margin: 0 0.75rem;
  width: 55px;
  height: 2rem;
  background: none;
  border: none;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  text-align-last: center;
  font-size: 1rem;
  font-weight: 500;
}
.chain-row-2 .col .freq.default {
  color: rgba(32, 48, 64, 0.5);
  font-weight: 400;
}

.chain-row-2 .col .form-control.freq:focus, .chain-row-2 .col .form-control.freq:active {
  outline: none;
}

.chain-row-2 .col span.number {
  color: rgba(32, 48, 64, 0.75);
}


/* Component Overview Fans*/
.overview-fans .rpms {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.overview-fans .highlight {
  padding: 2rem 1rem;
  margin: 0.5rem;
}


/* Component Overview Temps*/
.overview-temps .row {
  flex-wrap: nowrap;
}
.overview-temps .highlight {
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
}


/* Component Miner Status*/
.overview-miner-status {
  display: flex;
}
.overview-miner-status .chain_acn {
  margin-right: 1rem;
}
.overview-miner-status .chain_acs {
  font-size: 1.5rem;
  line-height: 1.1;
}


/* dialogs to delete after bootstrap implementing*/
/* dialogs */
.dialog-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: none;
}
.dialog-background.active {
  display: block;
}
.dialog-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: translateY(-100%);
  transition: all .33s ease-in-out;
}
.dialog-wrap.active {
  transform: translateY(0%);  
}
.dialog {
  /* flex: 0 0 300px; */
  width: 320px;
  position: absolute;
  left: 50%;  
  top: 10%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 64, 128, 0.15);
  border-radius: 0.5rem;   
}

.dialog-title, .dialog-content, .dialog-buttons {
  margin: 1rem 1.5rem;
}
.dialog-content {
  padding-bottom: 1rem;
}
.dialog-buttons {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}
.dialog-buttons .btn {
  flex: 0 0 100px;
  margin-left: 1rem;
}







/* override inputs */
.btn {
  border-radius: 6px;
  text-transform: uppercase;
  padding: 0.6rem 2.4rem;
  font-weight: 500;  
}

.btn-primary, .btn-secondary, .btn-success, .btn-danger, .btn-warning, .btn-info, .btn-dark {
  box-shadow: 0 3px 6px 0 rgba(0, 64, 128, 0.25);
}
.btn-primary {
  background-color: #007AF2;
}
.btn.disabled, .btn:disabled {
  opacity: .5;
}

.btn-secondary {
  background-color: #FFFFFF;
  border-color: #FEFEFE;
  color: #007AF2;
  box-shadow: 0 2px 6px 0 rgba(0, 64, 128, 0.15);
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: #FEFEFE;
  border-color: #FEFEFE;
  color: #007AF2;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  background-color: #FEFEFE;
  border-color: #FEFEFE;
  color: #007AF2;
}

.btn-light {
  color: #007AF2;
  background-color: #F5F7FA;
}
.btn-link:hover {
  text-decoration: none;
}

.custom-file{
  height: calc(1.5em + .75rem + 6px);  
}
.custom-file-input {
  height: calc(1.5em + .75rem + 6px);  
}
.custom-file-label {
  padding: 0.5rem;
  background-color: #FAFCFF;
  font-size: 1.15rem;
  height: calc(1.5em + .75rem + 4px);
  margin-right: 3px;  
}
.custom-file-label::after {
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0 2px 6px 0 rgba(0, 64, 128, 0.15);
  background-color: #007AF2;
  color: #FFFFFF;
  text-transform: uppercase;
  border-radius: 6px;    
  padding: 0.6rem 1.2rem;
  height: calc(1.5em + .75rem + 6px);
  margin-right: -3px;
}
.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Select";
}

.form-control {
  padding: 0.5rem;
  background-color: #FAFCFF;
  font-size: 1.15rem;
  height: calc(1.5em + .75rem + 4px);
}

.form-control[readonly] {
  background-color: #FAFCFF;
}

.form-control-unit {
  position: absolute;
  right: 8px;
  top: 8px;
}

label {
  color: rgba(32, 48, 64, 0.75);
  margin-bottom: .25rem;
}

.custom-select {
  padding: 0.5rem;
  background-color: #FAFCFF;
  font-size: 1.15rem;
  height: calc(1.5em + .75rem + 4px);
}

.custom-select.multiselect {
  height: 20rem;
}

.custom-control {
  min-height: 2.5rem;
  padding-left: 2.5rem;
}
.custom-control label {
  color: #203040;
  font-size: 1.15rem;
}
.custom-control-label::before {
  width: 1.5rem;
  height: 1.5rem;
  left: -2.5rem;
  top: 0;
}
.custom-control-label::after {
  width: 1.5rem;
  height: 1.5rem;
  left: -2.5rem;
  top: 0;
}
.custom-control-input {
  width: 1.5rem;
  height: 1.5rem;
}
.custom-control-input:checked~.custom-control-label::before {
  border-color: #007AF2;
  background-color: #007AF2;
}

.custom-control-right .custom-control-input, .custom-control-right .custom-control-label {
  position: absolute;
  right: 0;
}
.custom-control-right .custom-control-label:before, .custom-control-right .custom-control-label:after {
  left: unset;
  top: 0.5rem;
  right: 0;
}

.progress {
  height: 1.8rem;
  background-color: rgba(0, 122, 242, 0.08);
  border-radius: 1rem;
  position: relative;
}
.progress-bar {
  background-color: rgba(0, 122, 242, 0.2);
  border-radius: 1rem;
  color: #203040;
  font-size: 1rem;
}
.progress > label {
  margin: 0;
  font-size: 1rem;
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 0.15rem;
}

textarea, textarea.form-control {
  resize: vertical;
  font-size: 1rem;
  min-height: 8.3rem;
}

.custom-slider {
  display: flex;
  flex-direction: column;
}
.custom-slider .custom-range {
  height: 0.8rem;
}
.custom-slider .row {
  display: flex;
  flex-direction: row;
}
.custom-slider .btn-plus, .custom-slider .btn-minus {
  font-size: 2rem;
  padding: 0;
  position: absolute;
  width: 2.5rem;
  height: 1em;
  line-height: 1.7rem;
  top: 1px;
  color: #495057;
}
.custom-slider .btn-plus {
  right: 0;
}
.custom-slider .btn-minus {
  left: 0;
}
.custom-slider .btn-plus:focus, .custom-slider .btn-minus:focus {
  box-shadow: none;
}
.custom-slider .form-control {
  padding: 0 2.5rem;
  text-align: center;
  height: calc(1.5em + .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-menu {
  min-width: 12rem;
}
.dropdown-menu .custom-control {
  margin: 0.5rem 1rem;
}
.dropdown-toggle::after {
  margin-left: 0.5em;
}


/* tabs */

.nav-tabs {
  border-bottom: 2px solid #FFF;
  margin-bottom: 1.5rem;
}
.nav-tabs .nav-item {
  margin-bottom: -2px;
  margin-right: 1.5rem;
}
.nav-tabs .nav-item .nav-link {
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  border-bottom: 2px solid #FFF;
  padding: 0.5rem 0;
}
.nav-tabs .nav-item .nav-link:hover {
  border: none;
  border-bottom: 2px solid #FFF;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: transparent;
  color: #007AF2;
  border: none;
  border-bottom: 2px solid #007AF2;
}
.nav-tabs .nav-item .nav-link.active:hover {
  border: none;
  border-bottom: 2px solid #007AF2;
}



/* modals */
.modal-content {
  border: none;
  box-shadow: 0 2px 8px 0 rgba(0, 64, 128, 0.15);
  border-radius: 0.5rem;
}
.modal-header {
  padding: 1.5rem;
  border-bottom: none;
}
.modal-body, .modal-result {
  padding: 0 1.5rem;
}
.modal-footer {
  padding: 1.5rem;
  border-top: none;
}


/*page form*/
.page-form .row {
  justify-content: center;
}
.page-form .row .col {
  flex: 0 0 320px;
  width: 320px;
}
.page-form-actions {
  display: flex;
  justify-content: space-between;
}


/*page customers*/
.customer-panels {
  margin: 1rem -1rem 2rem -1rem;
}
.customer-panel {
  min-width: 260px;
  display: flex;
  cursor: pointer;
}
.customer-panel:hover {
  background-color: rgba(0, 122, 242, 0.02);
}
.customer-panel .customer-logo {
  font-size: 4rem;
  line-height: 3rem;
  color: rgba(0, 122, 242, 0.2);
  margin-right: 1rem;
}


.toasts {
  position: absolute;
  width: 280px;
  right: 2rem;
  z-index: 999999;
}
.toast {
  border-radius: 0.5rem;
  font-size: 1rem;

}
.toast-header {
  padding: 0.5rem 1rem;
  background-color: #007AF2;
  color: #FFFFFF;
}

.toast-header .close {
  color: #FFFFFF;
  opacity: 0.75;
}

.toast-header.error {
  background-color: rgb(255, 66, 66);
}

.toast-header.success {
  background-color: #5AB369;
  
}

.toast-body {
  padding: 1rem;
}

textarea.privateKey {
  height: 520px;
  resize: none;
  font-size: 88%;
}

textarea.publicKey {
  height: 190px;
  resize: none;
  font-size: 88%;
}

.headerSelect {
  display: inline-block;
  width: auto;
  background-color: transparent;
  padding: 0 1rem;
  margin: 0 1rem;
  font-size: 2rem;
}

.version {
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 10000;
  color: rgba(32, 48, 64, 0.5);
}

@font-face {
  font-family: 'terminator_genisysregular';
  src: url('fonts/terminator_genisys-webfont.woff2') format('woff2'),
       url('fonts/terminator_genisys-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}